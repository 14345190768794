












































































import Vue from "vue";
import Component from "vue-class-component";
import FormFile from "./FormFile.vue";
import Document from "./Document.vue";

import { formatDateTime, isDocumentNull, ModifDocument } from "./logic/utils";
import { ControllerDocument } from "./logic/controller";
import {
  PublicDocument,
  Contrainte,
  PublicContrainte
} from "@/shared/logic/types";
import { Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";

const NeededDocumentProps = Vue.extend({
  props: {
    controller: Object as () => ControllerDocument,
    contrainte: Object as () => PublicContrainte,
    documents: Array as () => PublicDocument[],
    border: String
  }
});

@Component({
  components: { Document, FormFile }
})
export default class NeededDocument extends NeededDocumentProps {
  $refs!: {
    downloadButton: BButton;
  };

  get showAdd() {
    return this.contrainte.max_docs > this.documents.length;
  }

  get tooltipTmp() {
    return `Ce document sera supprimé automatiquement <b>${this.contrainte.jours_valide} jours</b> après son ajout.`;
  }

  onUpload(index: number, newDoc: PublicDocument) {
    const docs: PublicDocument[] = JSON.parse(JSON.stringify(this.documents));
    docs[index] = newDoc;
    this.$emit("changed", docs);
  }

  onDelete(index: number, newDoc: PublicDocument) {
    let docs: PublicDocument[] = JSON.parse(JSON.stringify(this.documents));
    docs = docs.filter((_, i) => i != index);
    this.$emit("changed", docs);
  }
}
