// -- DONT EDIT - autogenerated from main.go

export const Equipier = {
  /** PUT */ AjouteDocumentEquipier: (cryptedId: string) =>
    "/:cryptedId".replace(":cryptedId", cryptedId),
  /** POST */ UpdateCharte: (cryptedId: string) =>
    "/:cryptedId/charte".replace(":cryptedId", cryptedId),
  /** GET */ FormulaireEquipier: (cryptedId: string) =>
    "/:cryptedId".replace(":cryptedId", cryptedId),
  /** GET */ DatasEquipier: (cryptedId: string) =>
    "/:cryptedId/datas".replace(":cryptedId", cryptedId),
  /** GET */ DatasJoomeo: (cryptedId: string) =>
    "/:cryptedId/datas/joomeo".replace(":cryptedId", cryptedId),
  /** POST */ UpdateEquipier: (cryptedId: string) =>
    "/:cryptedId".replace(":cryptedId", cryptedId)
};

export const Vote = {
  /** GET */ PageAdmin: (key: string) =>
    "/vote_admin/:key".replace(":key", key),
  /** POST */ InviteAll: (key: string) =>
    "/vote_admin/:key/api/membres".replace(":key", key),
  /** GET */ GetVotes: (key: string) =>
    "/vote_admin/:key/api/votes".replace(":key", key),
  /** POST */ UpdateVote: (key: string) =>
    "/vote_admin/:key/api/votes".replace(":key", key),
  /** DELETE */ DeleteVote: (key: string) =>
    "/vote_admin/:key/api/votes".replace(":key", key),
  /** GET */ ClearVote: (key: string) =>
    "/vote_admin/:key/api/votes/clear".replace(":key", key),
  /** GET */ GetMembres: (key: string) =>
    "/vote_admin/:key/api/membres".replace(":key", key),
  /** PUT */ InviteOne: (key: string) =>
    "/vote_admin/:key/api/membres".replace(":key", key),
  /** PUT */ CreateVote: (key: string) =>
    "/vote_admin/:key/api/votes".replace(":key", key),
  /** POST */ LockVote: (key: string) =>
    "/vote_admin/:key/api/votes/lock".replace(":key", key),
  /** GET */ ExportBilanVotes: (key: string) =>
    "/vote_admin/:key/api/votes/export".replace(":key", key)
};

export const Passwords = {
  /** GET */ PagePasswords: (key: string) =>
    "/passwords/:key".replace(":key", key),
  /** GET */ GetPasswords: (key: string) =>
    "/passwords/:key/api".replace(":key", key),
  /** PUT */ CreatePassword: (key: string) =>
    "/passwords/:key/api".replace(":key", key),
  /** POST */ UpdatePassword: (key: string) =>
    "/passwords/:key/api".replace(":key", key),
  /** DELETE */ DeletePassword: (key: string) =>
    "/passwords/:key/api".replace(":key", key)
};

export const Directeurs = {
  /** POST */ UpdateInscrit: "inscrits",
  /** DELETE */ DeleteContrainte: "contraintes",
  /** POST */ UpdatePlages: "groupes/plages",
  /** GET */ LoadGroupeAnimateurs: "groupes/animateurs",
  /** POST */ SetDocumentsEquipe: "equipe/pieces",
  /** GET */ GetEquipe: "equipe",
  /** GET */ ExportsExcel: (categorie: string) =>
    "exports/:categorie".replace(":categorie", categorie),
  /** GET */ GetInscrits: "inscrits",
  /** POST */ DownloadDocumentInscrits: "inscrits/documents",
  /** POST */ DownloadFicheSanitaire: "inscrits/fiche_sanitaire",
  /** POST */ ModifieEquipier: "equipe",
  /** POST */ InviteFormulaireEquipier: "equipe/invite",
  /** GET */ PreviewListeVetements: "liste_vetements/preview",
  /** POST */ UpdateLettreDirecteur: "lettre_directeur",
  /** GET */ GetJoomeo: "photos",
  /** PUT */ AjouteBonusDoc: "details/document",
  /** PUT */ CreateGroupe: "groupes",
  /** POST */ UpdateInscritsAnimateur: "groupes/animateurs/inscrits",
  /** POST */ UpdateListeVetements: "liste_vetements",
  /** POST */ RenvoieLienFicheSanitaire: "inscrits/renvoie_fiche_sanitaire",
  /** POST */ Loggin: "identification",
  /** GET */ GetDetails: (categorie: string) =>
    "details/:categorie".replace(":categorie", categorie),
  /** POST */ UpdateGroupe: "groupes",
  /** PUT */ AddGroupeAnimateurs: "groupes/animateurs",
  /** GET */ PreviewDocumentInscrits: "inscrits/documents",
  /** PUT */ AddEquipier: "equipe",
  /** GET */ GetListeVetements: (default_: string) =>
    "liste_vetements/:default".replace(":default", default_),
  /** GET */ DataLoggin: "identification",
  /** POST */ UploadImageLettre: (id_camp: string, token: string) =>
    "lettre_directeur/upload_image/:id_camp/:token"
      .replace(":id_camp", id_camp)
      .replace(":token", token),
  /** GET */ GetDocumentsEquipe: "equipe/pieces",
  /** POST */ SetContactUploader: "photos",
  /** GET */ LoadSondages: "retours",
  /** POST */ UpdateGroupesContraintes: "groupes/contraintes",
  /** POST */ UpdateMessageView: "inscrits/message",
  /** DELETE */ DeleteGroupe: "groupes",
  /** POST */ DeleteGroupeAnimateur: "groupes/animateurs",
  /** PUT */ AddDocumentEquipe: "equipe/pieces/upload",
  /** GET */ GetLettreDirecteur: "lettre_directeur",
  /** POST */ UpdateDetails: (categorie: string) =>
    "details/:categorie".replace(":categorie", categorie),
  /** PUT */ AutoRepartitInscritsAnimateur: "groupes/animateurs/inscrits",
  /** GET */ DownloadDocumentsEquipe: "equipe/pieces/download",
  /** POST */ RechercheProfil: "equipe/recherche",
  /** DELETE */ DeleteEquipier: "equipe",
  /** POST */ RenvoieLienJoomeo: "photos/renvoie_lien",
  /** GET */ LoadContraintes: "contraintes",
  /** POST */ UpdateContrainte: "contraintes",
  /** GET */ LieDocument: "contraintes/lie_document",
  /** GET */ LoadGroupes: "groupes",
  /** POST */ ModifieJoursPresence: "inscrits/jours_presence",
  /** PUT */ AddContactsJoomeo: "photos",
  /** DELETE */ DeleteContact: "photos",
  /** PUT */ CreateContrainte: "contraintes"
};

export const Misc = {
  /** GET */ HandleLogiciel: "/logiciel",
  /** POST */ DownloadLogiciel: "/logiciel",
  /** POST */ SearchMail: "/lien_espace_perso"
};

export const Inscriptions = {
  /** GET */ FormulaireInscription1: "",
  /** GET */ FormulaireInscription2: (preselected: string) =>
    "/:preselected".replace(":preselected", preselected),
  /** GET */ HandleLoadDataInscriptions: "/api",
  /** POST */ EnregistreInscriptionComplete: "/api",
  /** POST */ RejoueInscriptionComplete: "/api/no-check-mail",
  /** GET */ CheckMail: "/api/check-mail",
  /** POST */ EnregistreInscriptionSimple: "/api/simple"
};

export const EspacePerso = {
  /** PUT */ CreateDocument: (key: string) =>
    "/espace_perso/:key/api/document".replace(":key", key),
  /** POST */ UpdateOptionsParticipants: (key: string) =>
    "/espace_perso/:key/api/participants".replace(":key", key),
  /** POST */ EditMessage: (key: string) =>
    "/espace_perso/:key/api/message".replace(":key", key),
  /** GET */ GetData: (key: string) =>
    "/espace_perso/:key/api/datas".replace(":key", key),
  /** DELETE */ DeleteAide: (key: string) =>
    "/espace_perso/:key/api/aide".replace(":key", key),
  /** GET */ TransfertFicheSanitaire: (key: string) =>
    "/espace_perso/:key/api/fiche_sanitaire".replace(":key", key),
  /** POST */ UpdateFicheSanitaire: (key: string) =>
    "/espace_perso/:key/api/fiche_sanitaire".replace(":key", key),
  /** GET */ MarkConnection: (key: string) =>
    "/espace_perso/:key/api/connected".replace(":key", key),
  /** GET */ DownloadAttestationPresence: (key: string) =>
    "/espace_perso/:key/api/download/presence".replace(":key", key),
  /** POST */ DemandePaimentEnLigne: (key: string) =>
    "/espace_perso/:key/api/paiement_en_ligne".replace(":key", key),
  /** GET */ GetMetas: (key: string) =>
    "/espace_perso/:key/api/metas".replace(":key", key),
  /** POST */ SaveSondage: (key: string) =>
    "/espace_perso/:key/api/sondage".replace(":key", key),
  /** POST */ ConfirmePlaceliberee: (key: string) =>
    "/espace_perso/:key/api/placeliberee".replace(":key", key),
  /** GET */ LoadJoomeo: (key: string) =>
    "/espace_perso/:key/api/joomeo".replace(":key", key),
  /** PUT */ CreateAide: (key: string) =>
    "/espace_perso/:key/api/aide".replace(":key", key),
  /** POST */ UpdateAide: (key: string) =>
    "/espace_perso/:key/api/aide".replace(":key", key),
  /** GET */ DownloadFacture: (key: string) =>
    "/espace_perso/:key/api/download/facture".replace(":key", key),
  /** PUT */ CreeMessage: (key: string) =>
    "/espace_perso/:key/api/message".replace(":key", key),
  /** DELETE */ DeleteMessage: (key: string) =>
    "/espace_perso/:key/api/message".replace(":key", key),
  /** GET */ GetFinances: (key: string) =>
    "/espace_perso/:key/api/finances".replace(":key", key)
};
