// scroll and return `true` if the form is valid
export function scrollToError(form: HTMLElement) {
  const errorsEls = form.getElementsByClassName("is-invalid");
  const el = errorsEls.item(0) || form.querySelector(".form-group:invalid");
  if (el != null && el.parentElement != null) {
    el.parentElement.scrollIntoView(false);
  }
  return el == null;
}

export interface ValidEvent {
  spinning: boolean;
}

export interface ValidEventFile {
  event: ValidEvent;
  file: File;
}

export enum SuccesPosition {
  TopRight = "b-toaster-top-right",
  BottomLeft = "b-toaster-bottom-left",
  BottomRight = "b-toaster-bottom-right"
}
