















import Vue from "vue";
import Component from "vue-class-component";
import { TrajetBus, BusCamp } from "@/shared/logic/types";

const BusParticipantProps = Vue.extend({
  props: {
    bus: String,
    infoBus: Object as () => BusCamp,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "bus",
    event: "change"
  }
});

function formatTrajet(trajet: TrajetBus) {
  let str = `${trajet.rendez_vous}`;
  if (trajet.prix) {
    str += ` - ${trajet.prix}€`;
  }
  return str;
}

@Component({})
export default class BusParticipant extends BusParticipantProps {
  get description() {
    return (this.infoBus.commentaire || "").replace("\n", "<br/>");
  }

  get optionsBus() {
    const allerInfo = "Aller - " + formatTrajet(this.infoBus.aller);
    const retourInfo = "Retour - " + formatTrajet(this.infoBus.retour);
    const allerRetourInfo =
      "Aller / Retour - " +
      formatTrajet({
        rendez_vous: `${this.infoBus.aller.rendez_vous} / ${this.infoBus.retour.rendez_vous}`,
        prix: this.infoBus.aller.prix + this.infoBus.retour.prix
      });
    return [
      { value: "", text: "Aucun" },
      { value: "aller", text: allerInfo },
      { value: "retour", text: retourInfo },
      { value: "aller_retour", text: allerRetourInfo }
    ];
  }
}
