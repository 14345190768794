












import Vue from "vue";
import Component from "vue-class-component";

const LogoProps = Vue.extend({
  props: {}
});

@Component({})
export default class Logo extends LogoProps {}
