import {
  Date_,
  ModePaiment,
  ModePaimentLabels,
  OptionQuotientFamilial,
  OptionsCamp,
  PublicDocument,
  ResponsableLegal,
  Sexe,
  Time
} from "@/shared/logic/types";

export const MaxFileSize = 5000000; // bytes

export const isNullTime = (s: string) =>
  !s || s.length < 10 || s.substr(0, 10) == "0001-01-01";

export function formatDateTime(dateString: Time): string {
  if (isNullTime(dateString)) return "";
  return new Date(dateString).toLocaleTimeString("fr-FR", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
}

export function formatDateObject(date: Date): string {
  return date.toLocaleDateString("fr-FR", { timeZone: "UTC" });
}

export function formatDate(dateString: Date_): string {
  if (isNullTime(dateString)) return "";
  return formatDateObject(new Date(dateString));
}

export function descriptionDocument(doc: PublicDocument) {
  var dt = formatDateTime(doc.date_heure_modif);
  dt = dt ? `, modifié le <i>${dt}</i>` : "";
  return `<i>${doc.nom_client}</i>${dt}`;
}

export function age(debut: Date, dateNaissance: Date_) {
  const naissance = new Date(dateNaissance);
  if (!debut || !naissance) {
    return -1;
  }
  const diff = debut.getFullYear() - naissance.getFullYear();
  const isNaissanceBefore =
    naissance.getMonth() < debut.getMonth() ||
    (naissance.getMonth() == debut.getMonth() &&
      naissance.getDate() <= debut.getDate());
  if (isNaissanceBefore) {
    return diff;
  }
  return diff - 1;
}

export function formatModePaiement(mode: ModePaiment) {
  return ModePaimentLabels[mode] || "";
}

export function isDocumentNull(doc: PublicDocument | null) {
  return doc == null || !doc.id_crypted;
}

export const nullTimeString = "0001-01-01T00:00:00Z" as Time;
export const nullDateString = "0001-01-01" as Date_;

export function isNullDateString(date: string) {
  if (date.length < 10) return true; // invalide
  if (date.length == 10) {
    date = date + "T00:00";
  }
  return date.startsWith("0001-01-01T00:00");
}

export function nullDocument(): PublicDocument {
  return {
    id_crypted: "",
    nom_client: "",
    taille: 0,
    date_heure_modif: nullTimeString,
    url_miniature: "",
    url_download: ""
  };
}

/** Comme id_crypted change entre deux modifications
 * cet objet garde la trace de l'id original */
export interface ModifDocument {
  idCrypted: string;
  document: PublicDocument;
}

export function nullResponsableInscription(): ResponsableLegal {
  return {
    adresse: "",
    code_postal: "",
    date_naissance: nullDateString,
    lienid: { crypted: "", id: 0, valid: false },
    mail: "",
    nom: "",
    pays: "",
    prenom: "",
    sexe: Sexe.SAucun,
    tels: [],
    ville: ""
  };
}

interface camp {
  options: OptionsCamp;
}
export function hasBus(c: camp | null) {
  return c && c.options.bus.actif;
}

export function hasMaterielSki(c: camp | null) {
  return c && c.options.materiel_ski && c.options.materiel_ski.actif;
}

const reSepTel = /[ -/;\t]/g;

function splitBySize(a: string) {
  const b = [];
  for (var i = 2; i < a.length; i += 2) {
    // length 2, for example
    b.push(a.slice(i - 2, i));
  }
  b.push(a.slice(a.length - (2 - (a.length % 2)))); // last fragment
  return b;
}

export function formatTel(tel: string) {
  tel = tel.replace(reSepTel, "");
  if (tel.length < 8) {
    return splitBySize(tel).join(" ");
  } // numéro incomplet, on insert des espaces
  const start = tel.length - 8; // 8 derniers chiffres
  const chunks = [tel.substr(0, start)];
  for (let i = 0; i < 4; i++) {
    chunks.push(tel.substr(start + 2 * i, 2));
  }
  return chunks.join(" ");
}

const securiteSocialSplitIndexes = [1, 3, 5, 7, 10, 13, 15, 0];
const N = securiteSocialSplitIndexes.length;
export function formatSecuriteSociale(s: string) {
  s = s.replace(/\s/g, "");
  const chunks = securiteSocialSplitIndexes.map((_, i) => {
    const indexStart = i == 0 ? 0 : securiteSocialSplitIndexes[i - 1];
    const indexEnd = i == N - 1 ? undefined : securiteSocialSplitIndexes[i];
    const chunk = s.substring(indexStart, indexEnd);
    return chunk;
  });
  return chunks.filter(c => !!c).join(" ");
}

// Décode le contenu de la balise "meta" contenant des données additionnelles
export function decodeServerPayload<T>(): T | null {
  const meta = document.head.querySelector('meta[name="serverPayload"]');
  if (meta == null) return null;
  const json = (meta as HTMLMetaElement).content;
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}

const patternMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isEmailValid(mail: string) {
  return patternMail.test(mail);
}

const isZero = <T extends string | number>(a: T) => a == "" || a == 0;

export function enumToOptions<T extends string | number>(
  enums: { [key in T]: string }
) {
  const out: { value: T; text: string }[] = [];
  for (const value in enums) {
    const text = enums[value];
    out.push({ value, text });
  }
  return out.sort((a, b) => {
    if (isZero(a.value)) {
      return -1;
    }
    return a.text.localeCompare(b.text);
  });
}

export function isQuotientFamilialActif(qf: OptionQuotientFamilial) {
  return qf.filter(v => v != 0).length != 0;
}
