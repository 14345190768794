












import Vue from "vue";
import Component from "vue-class-component";

const RequiredSexeProps = Vue.extend({
  props: {
    sexe: String,
    invalidFeedback: {
      type: String,
      default: "Merci de préciser votre sexe."
    }
  },
  model: {
    prop: "sexe",
    event: "change"
  }
});

@Component({})
export default class RequiredSexe extends RequiredSexeProps {
  optionsSexe = [
    { text: "Homme", value: "M" },
    { text: "Femme", value: "F" }
  ];

  onChange(v: string) {
    this.$emit("change", v);
  }
}
