import Vue from "vue";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  BIconDownload,
  BIconUpload,
  BIconTrash,
  BIconLock,
  BIconUnlock,
  BIconExclamationCircle,
  BIconInfo,
  BIconPencil,
  BIconChevronRight,
  BIconChevronDown,
  BIconX,
  BIconPlus,
  BIconCheck,
  BIconArrowRepeat,
  BIconEnvelope,
  BIconClock,
  BIconCreditCard,
  BIconThreeDotsVertical,
  BIconSearch
} from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.component("BIconDownload", BIconDownload);
Vue.component("BIconUpload", BIconUpload);
Vue.component("BIconTrash", BIconTrash);
Vue.component("BIconLock", BIconLock);
Vue.component("BIconUnlock", BIconUnlock);
Vue.component("BIconExclamationCircle", BIconExclamationCircle);
Vue.component("BIconInfo", BIconInfo);
Vue.component("BIconPencil", BIconPencil);
Vue.component("BIconChevronRight", BIconChevronRight);
Vue.component("BIconChevronDown", BIconChevronDown);
Vue.component("BIconX", BIconX);
Vue.component("BIconPlus", BIconPlus);
Vue.component("BIconCheck", BIconCheck);
Vue.component("BIconArrowRepeat", BIconArrowRepeat);
Vue.component("BIconEnvelope", BIconEnvelope);
Vue.component("BIconClock", BIconClock);
Vue.component("BIconCreditCard", BIconCreditCard);
Vue.component("BIconThreeDotsVertical", BIconThreeDotsVertical);
Vue.component("BIconSearch", BIconSearch);
