















import Vue from "vue";
import Component from "vue-class-component";
import { ValidEvent } from "./utils";

const BtnProps = Vue.extend({
  props: {
    variant: String,
    disabled: {
      type: Boolean,
      default: false
    },
    label: String,
    block: {
      type: Boolean,
      default: false
    }
  }
});

@Component({})
export default class Btn extends BtnProps {
  validEvent: ValidEvent = { spinning: false };

  get spinning() {
    return this.validEvent.spinning;
  }

  get innerDisabled() {
    return this.disabled || this.spinning;
  }

  onClick() {
    if (this.spinning) {
      return; // on annule les multiples requêtes
    }
    this.$emit("click", this.validEvent);
  }
}
