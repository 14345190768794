








import Vue from "vue";
import Component from "vue-class-component";
import { ErrorOutput, Output } from "../logic/notifications";

const ErrorProps = Vue.extend({
  props: {
    output: Object as () => Output
  }
});

@Component({})
export default class Error extends ErrorProps {
  get error() {
    return this.output.error;
  }
  get show() {
    return this.error != null;
  }

  set show(b: boolean) {
    if (!b) {
      this.output.error = null;
    }
  }

  get title() {
    if (this.error == null) return "";
    return `${this.error.kind} (code ${this.error.code})`;
  }
}
