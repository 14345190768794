





















































import Vue from "vue";
import Component from "vue-class-component";
import Btn from "./Btn.vue";

import { MaxFileSize } from "./logic/utils";
import { Watch } from "vue-property-decorator";
import { ValidEvent, ValidEventFile } from "./utils";

const FormFileProps = Vue.extend({
  props: {
    title: String,
    variant: String,
    invalidFeedback: {
      type: String as () => String | null,
      default: null
    },
    showValidation: Boolean,
    hideBtnUpload: {
      type: Boolean,
      default: false
    }
  }
});

@Component({
  components: { Btn }
})
export default class FormFile extends FormFileProps {
  private MaxFileSize = MaxFileSize;
  private AcceptedTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    ".pdf"
  ];
  file: File | null = null;

  get isFileInvalid(): string | null {
    if (this.file == null) return "Aucun fichier";
    if (this.file.size > MaxFileSize) {
      return `Le fichier est trop lourd : max
              ${MaxFileSize / 1000000} MB.`;
    }
    let type = this.file.type;
    // IE workaround : file.type is empty
    if (this.file.name.toLowerCase().endsWith(".pdf")) {
      type = ".pdf";
    }
    if (!this.AcceptedTypes.includes(type)) {
      return `Le type du fichier est invalide.`;
    }
    return null;
  }

  get validationState(): boolean | null {
    if (!this.showValidation) return null;
    return this.isFileInvalid == null;
  }

  private fileInfo(files: File[]) {
    if (!files || files.length == 0) return "";
    const file = files[0];
    return `${file.name} (${(file.size / 1000000).toFixed(1)} MB)`;
  }

  onUpload(event: ValidEvent) {
    if (this.file == null) return;
    event.spinning = true;
    const upEvent: ValidEventFile = { event, file: this.file };
    this.$emit("upload", upEvent);
    this.file = null;
  }
}
