




















import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Output } from "../logic/notifications";
import { formatDateTime } from "../logic/utils";
import { SuccesPosition } from "../utils";

const SuccessProps = Vue.extend({
  props: {
    output: Object as () => Output,
    position: {
      type: String as () => SuccesPosition,
      default: SuccesPosition.BottomRight
    }
  }
});

@Component({})
export default class Success extends SuccessProps {
  get visible() {
    return this.output.success != null;
  }
  set visible(b: boolean) {
    if (!b) {
      this.output.success = null;
    }
  }

  get notification() {
    return this.output.success;
  }

  time() {
    return new Date().toLocaleTimeString("fr-FR", {
      hour: "numeric",
      minute: "numeric"
    });
  }
}
