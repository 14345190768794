























































































import Vue from "vue";
import Component from "vue-class-component";

import FormFile from "./FormFile.vue";

import { descriptionDocument } from "./logic/utils";
import { BvModalEvent } from "bootstrap-vue";
import { ValidEvent, ValidEventFile } from "./utils";
import { ControllerDocument } from "./logic/controller";
import { PublicDocument } from "@/shared/logic/types";

const ControleDocumentProps = Vue.extend({
  props: {
    controller: Object as () => ControllerDocument,
    document: Object as () => PublicDocument,
    hideDelete: {
      type: Boolean,
      default: false
    },
    hideUpload: {
      type: Boolean,
      default: false
    },
    disableUpload: {
      type: Boolean,
      default: false
    }
  }
});

@Component({
  components: { FormFile }
})
export default class ControleDocument extends ControleDocumentProps {
  confirmeDelete = false;
  spinningDelete = false;

  showUpload = false;

  get descriptionDocument() {
    return descriptionDocument(this.document);
  }
  get tooltipDownload() {
    return `<b>Télécharger</b> le document (${this.descriptionDocument})`;
  }

  tooltipUpload = "Remplacer le document...";
  tooltipDelete = "Supprimer le document...";

  async deleteDocument(event: BvModalEvent) {
    event.preventDefault(); // garde le dialog visible
    this.spinningDelete = true;
    const out = await this.controller.deleteDocument(this.document);
    this.spinningDelete = false;
    this.confirmeDelete = false;
    if (out) {
      this.$emit("deleted", this.document);
    }
  }

  async uploadDocument(event: ValidEventFile) {
    const rep = await this.controller.uploadDocument(
      event.file,
      this.document.id_crypted
    );
    event.event.spinning = false;
    this.showUpload = false;
    if (rep !== undefined) {
      this.$emit("uploaded", rep);
    }
  }
}
