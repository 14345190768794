













import Vue from "vue";
import Component from "vue-class-component";
import { Satisfaction } from "@/shared/logic/types";

const FieldSatisfactionProps = Vue.extend({
  props: {
    value: Number as () => Satisfaction,
    label: String,
    readonly: Boolean
  },
  model: {
    prop: "value",
    event: "change"
  }
});

@Component({})
export default class FieldSatisfaction extends FieldSatisfactionProps {}
