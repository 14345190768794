









import Vue from "vue";
import Component from "vue-class-component";

const MultiLineProps = Vue.extend({
  props: {
    message: String
  }
});

@Component({
  components: {}
})
export default class MultiLine extends MultiLineProps {
  get lines() {
    return this.message.split("\n");
  }
}
