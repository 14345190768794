
























































































import Vue from "vue";
import Component from "vue-class-component";
import { MaterielSkiCamp, MaterielSki } from "@/shared/logic/types";

const MaterielSkiParticipantProps = Vue.extend({
  props: {
    materielSki: Object as () => MaterielSki,
    infoMaterielSki: Object as () => MaterielSkiCamp,
    disabled: Boolean
  },
  model: {
    prop: "materielSki",
    event: "change"
  }
});

@Component({})
export default class MaterielSkiParticipant extends MaterielSkiParticipantProps {
  get options() {
    const textLoueur = `Je souhaite me procurer du matériel chez un <b>loueur</b>. Prévoir
                            <b>${this.infoMaterielSki.prix_loueur ||
                              "-"} €</b> à
                            régler à l'arrivée.`;

    const textAcve = `Pour les séjours à la Maison du Rocher, du matériel de ski est en
                        dépôt
                        sur site. Si les tailles correspondent, celui-ci peut être mis à
                        disposition sur place et <i>sous responsabilité des parents.</i>
                        Dans ce cas, une participation aux frais d'entretion de 
                        <b>${this.infoMaterielSki.prix_acve || "-"} €</b> est
                        demandée. Je souhaite <b>bénéficier de ce matériel</b>.`;
    return [
      { html: "Je viens avec mon propre matériel.", value: "" },
      { html: textLoueur, value: "loueur" },
      { html: textAcve, value: "acve" }
    ];
  }
}
