



















































import Vue from "vue";
import Component from "vue-class-component";
import { isNullTime } from "../logic/utils";
import { Watch } from "vue-property-decorator";
import { BFormInput } from "bootstrap-vue";
import { Date_ } from "../logic/types";

const DateFieldProps = Vue.extend({
  props: {
    dateString: (String as unknown) as () => Date_,
    label: String,
    invalidFeedback: String,
    required: Boolean,
    checkMajeur: Boolean,
    validated: Boolean,
    disabled: Boolean
  },
  model: {
    prop: "dateString",
    event: "change"
  }
});

interface InnerDate {
  day: number | null;
  month: number | null;
  year: number | null;
}

@Component({})
export default class DateField extends DateFieldProps {
  date: InnerDate = this.parseDate();

  $refs!: {
    year: HTMLInputElement;
  };

  @Watch("dateString")
  onChange() {
    this.date = this.parseDate();
  }

  parseDate() {
    if (isNullTime(this.dateString)) {
      return { day: null, month: null, year: null };
    }
    const d = new Date(this.dateString);

    return {
      day: d.getDate(),
      month: d.getMonth() + 1,
      year: d.getFullYear()
    };
  }

  get state() {
    if (!this.validated) return null;
    if (this.required || this.checkMajeur) {
      if (this.currentDate == null) return false;
      if (this.checkMajeur) {
        return this.age(this.currentDate) >= 18;
      }
    }
    return true;
  }

  age(date: Date) {
    const diff = new Date(Date.now() - date.valueOf());
    diff.setHours(0, 0, 0, 0); // attention au jour d'anniversaire
    return diff.getFullYear() - 1970;
  }

  get currentDate(): Date | null {
    if (!(this.date.year && this.date.month && this.date.day)) return null;
    return new Date(
      Date.UTC(this.date.year, this.date.month - 1, this.date.day)
    );
  }

  onInput() {
    // reset custom validity
    this.$refs.year.setCustomValidity("");

    if (this.currentDate == null) return; // date en train d'être tappée

    if (this.checkMajeur) {
      this.$refs.year.setCustomValidity(
        this.state === false ? "not-majeur" : ""
      );
    }
    let v = "";
    if (!isNaN(this.currentDate.getTime())) {
      v = this.currentDate.toISOString().split("T")[0];
    }
    this.$emit("change", v);
  }
}
