import axios, { AxiosResponse } from "axios";
import { Output } from "./notifications";
import { PublicDocument } from "@/shared/logic/types";

export const devMode = process.env.NODE_ENV != "production";
export const UrlServerBase = devMode
  ? "http://localhost:1323"
  : window.location.origin;
export const PublicStripeKey = devMode
  ? "pk_test_51IDnVwCMy4QEdLWawPM93PnRwkL5AqYpRxkYTLuMuosYdx41FSx9NbNzNILYP1SNPe0P50w1ihYihpOo3EZyfumL00IWh0x1XP"
  : "pk_live_51IDnVwCMy4QEdLWaB7xfMGDPXE2Rl5xtHxGJH8y2b8TbydYJY9iOFEBPPoMqyJr5o5q0q8lu95VdnPZtTXiLV4GV00polIBMSB";

export class BaseController {
  notifications: Output;

  constructor() {
    this.notifications = new Output();
  }

  uploadFile(
    url: string,
    file: File,
    idDocCrypted: string
  ): Promise<AxiosResponse<PublicDocument>> {
    const form = new FormData();
    form.append("file", file, file.name);
    form.append("crypted-id", idDocCrypted);
    return axios.post(url, form);
  }

  // Renvoie `false` en cas d'erreur
  async deleteDocument(doc: PublicDocument) {
    try {
      await axios.delete(UrlServerBase + "/document", {
        params: { "id-crypted": doc.id_crypted }
      });
      this.notifications.success = {
        title: "Suppression",
        message: "Le document a été supprimé avec succès."
      };
      return true;
    } catch (error) {
      this.notifications.onAxiosError(error);
      return false;
    }
  }

  async uploadDocument(file: File, idDocCrypted: string) {
    try {
      const rep = await this.uploadFile(
        UrlServerBase + "/document",
        file,
        idDocCrypted
      );
      this.notifications.success = {
        title: "Document modifié",
        message: "Le document a bien été modifié."
      };
      return rep.data;
    } catch (error) {
      this.notifications.onAxiosError(error);
    }
  }
}

/** remplace l'origin de l'url courante par celle
 du serveur backend (n'affecte que le mode dev)
 */
export function adjustUrlOrigin() {
  const loc = window.location;
  const url = UrlServerBase + loc.pathname + loc.search + loc.hash;
  return url.replace(/\/$/, "");
}

export interface ControllerDocument {
  deleteDocument(document: PublicDocument): Promise<boolean>;
  uploadDocument(
    file: File,
    idCrypted: string
  ): Promise<PublicDocument | undefined>;
}

/** Ajoute les meta données au fichier */
export function fileMeta(file: File, meta: any) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("meta", JSON.stringify(meta));
  return formData;
}
