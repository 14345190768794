export const DEPARTEMENTS = [
  "01 - Ain",
  "02 - Aisne",
  "03 - Allier",
  "04 - Alpes-de-Haute-Provence",
  "05 - Hautes-Alpes",
  "06 - Alpes-Maritimes",
  "07 - Ardèche",
  "08 - Ardennes",
  "09 - Ariège",
  "10 - Aube",
  "11 - Aude",
  "12 - Aveyron",
  "13 - Bouches-du-Rhône",
  "14 - Calvados",
  "15 - Cantal",
  "16 - Charente",
  "17 - Charente-Maritime",
  "18 - Cher",
  "19 - Corrèze",
  "2A - Corse-du-Sud",
  "2B - Haute-Corse",
  "21 - Côte-d'Or",
  "22 - Côtes-d'Armor",
  "23 - Creuse",
  "24 - Dordogne",
  "25 - Doubs",
  "26 - Drôme",
  "27 - Eure",
  "28 - Eure-et-Loir",
  "29 - Finistère",
  "30 - Gard",
  "31 - Haute-Garonne",
  "32 - Gers",
  "33 - Gironde",
  "34 - Hérault",
  "35 - Ille-et-Vilaine",
  "36 - Indre",
  "37 - Indre-et-Loire",
  "38 - Isère",
  "39 - Jura",
  "40 - Landes",
  "41 - Loir-et-Cher",
  "42 - Loire",
  "43 - Haute-Loire",
  "44 - Loire-Atlantique",
  "45 - Loiret",
  "46 - Lot",
  "47 - Lot-et-Garonne",
  "48 - Lozère",
  "49 - Maine-et-Loire",
  "50 - Manche",
  "51 - Marne",
  "52 - Haute-Marne",
  "53 - Mayenne",
  "54 - Meurthe-et-Moselle",
  "55 - Meuse",
  "56 - Morbihan",
  "57 - Moselle",
  "58 - Nièvre",
  "59 - Nord",
  "60 - Oise",
  "61 - Orne",
  "62 - Pas-de-Calais",
  "63 - Puy-de-Dôme",
  "64 - Pyrénées-Atlantiques",
  "65 - Hautes-Pyrénées",
  "66 - Pyrénées-Orientales",
  "67 - Bas-Rhin",
  "68 - Haut-Rhin",
  "69 - Rhône",
  "70 - Haute-Saône",
  "71 - Saône-et-Loire",
  "72 - Sarthe",
  "73 - Savoie",
  "74 - Haute-Savoie",
  "75 - Paris",
  "76 - Seine-Maritime",
  "77 - Seine-et-Marne",
  "78 - Yvelines",
  "79 - Deux-Sèvres",
  "80 - Somme",
  "81 - Tarn",
  "82 - Tarn-et-Garonne",
  "83 - Var",
  "84 - Vaucluse",
  "85 - Vendée",
  "86 - Vienne",
  "87 - Haute-Vienne",
  "88 - Vosges",
  "89 - Yonne",
  "90 - Territoire de Belfort",
  "91 - Essonne",
  "92 - Hauts-de-Seine",
  "93 - Seine-Saint-Denis",
  "94 - Val-de-Marne",
  "95 - Val-d'Oise",
  "971 - Guadeloupe",
  "972 - Martinique",
  "973 - Guyane",
  "974 - La Réunion",
  "976 - Mayotte"
];

export const HACCP = "haccp";
export const DOCUMENTS: { [key: string]: string } = {
  bafa: "BAFA",
  bafd: "BAFD",
  carte_id: "Carte d'identité/Passeport",
  permis: "Permis de conduire",
  sb: "Surveillant de baignade",
  secour: "Secourisme (PSC1 - AFPS)",
  bafd_equiv: "Equivalent BAFD",
  bafa_equiv: "Equivalent BAFA",
  vaccin: "Vaccins",
  [HACCP]: "Cuisine (HACCP)",
  carte_vitale: "Carte Vitale",
  scolarite: "Certificat de scolarité",
  autre: "Autre"
};

// q[i] < x <= q[i+1]
export const QuotientFamilial = [0, 359, 564, 714];

// copied from rawdata/pays.go
export const PAYS: { [key: string]: string } = {
  BV: "Bouvet",
  LK: "Sri Lanka",
  UY: "Uruguay",
  VA: "Saint-Siège",
  NI: "Nicaragua",
  DZ: "Algérie",
  DK: "Danemark",
  GD: "Grenade",
  ML: "Mali",
  PK: "Pakistan",
  PL: "Pologne",
  DM: "Dominique",
  GM: "Gambie",
  MG: "Madagascar",
  ZA: "Afrique du Sud",
  IE: "Irlande",
  PT: "Portugal",
  QA: "Qatar",
  MQ: "Martinique",
  NL: "Pays-Bas",
  BT: "Bhoutan",
  CX: "Christmas",
  DJ: "Djibouti",
  HU: "Hongrie",
  RU: "Russie",
  LU: "Luxembourg",
  MH: "Marshall",
  PS: "Palestine, État de",
  SN: "Sénégal",
  SD: "Soudan",
  AQ: "Antarctique",
  FO: "Féroé",
  GG: "Guernesey",
  AM: "Arménie",
  KH: "Cambodge",
  TK: "Tokelau",
  MF: "Saint-Martin",
  WS: "Samoa",
  AS: "Samoa américaines",
  GH: "Ghana",
  KZ: "Kazakhstan",
  GE: "Géorgie",
  SS: "Soudan du Sud",
  TT: "Trinité-et-Tobago",
  GA: "Gabon",
  PN: "Pitcairn",
  BH: "Bahreïn",
  BA: "Bosnie-Herzégovine",
  TD: "Tchad",
  LC: "Sainte-Lucie",
  AL: "Albanie",
  PW: "Palaos",
  PA: "Panama",
  AW: "Aruba",
  BS: "Bahamas",
  PR: "Porto Rico",
  PY: "Paraguay",
  VE: "Venezuela",
  CW: "Curaçao",
  JO: "Jordanie",
  MM: "Myanmar",
  BW: "Botswana",
  YT: "Mayotte",
  CK: "Cook",
  ER: "Érythrée",
  NG: "Nigéria",
  EG: "Égypte",
  GL: "Groenland",
  KG: "Kirghizistan",
  MK: "Macédoine du Nord",
  IR: "Iran",
  PE: "Pérou",
  TZ: "Tanzanie",
  ZW: "Zimbabwe",
  CN: "Chine",
  IN: "Inde",
  FM: "Micronésie",
  AX: "Åland",
  CC: "Cocos",
  CG: "Congo",
  GB: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
  AZ: "Azerbaïdjan",
  AE: "Émirats arabes unis",
  EH: "Sahara occidental",
  BN: "Brunéi Darussalam",
  ID: "Indonésie",
  MZ: "Mozambique",
  MW: "Malawi",
  MR: "Mauritanie",
  MS: "Montserrat",
  NC: "Nouvelle-Calédonie",
  OM: "Oman",
  BJ: "Bénin",
  BR: "Brésil",
  GF: "Guyane française",
  SX: "Saint-Martin",
  GS: "Géorgie du Sud-et-les Îles Sandwich du Sud",
  UG: "Ouganda",
  AI: "Anguilla",
  GY: "Guyana",
  PH: "Philippines",
  LI: "Liechtenstein",
  SY: "République arabe syrienne",
  CA: "Canada",
  SZ: "Eswatini",
  LR: "Libéria",
  IS: "Islande",
  MA: "Maroc",
  PG: "Papouasie-Nouvelle-Guinée",
  TW: "Taïwan",
  AD: "Andorre",
  BY: "Bélarus",
  BE: "Belgique",
  ZM: "Zambie",
  BM: "Bermudes",
  IM: "Île de Man",
  ME: "Monténégro",
  AG: "Antigua-et-Barbuda",
  TC: "Turks-et-Caïcos",
  AF: "Afghanistan",
  BQ: "Bonaire, Saint-Eustache et Saba",
  US: "États-Unis d'Amérique",
  HT: "Haïti",
  NE: "Niger",
  RW: "Rwanda",
  RS: "Serbie",
  SL: "Sierra Leone",
  CV: "Cabo Verde",
  CI: "Côte d'Ivoire",
  EC: "Équateur",
  TV: "Tuvalu",
  GQ: "Guinée équatoriale",
  MV: "Maldives",
  SM: "Saint-Marin",
  MO: "Macao",
  NO: "Norvège",
  UA: "Ukraine",
  LS: "Lesotho",
  MY: "Malaisie",
  NA: "Namibie",
  WF: "Wallis-et-Futuna",
  SK: "Slovaquie",
  TL: "Timor-Leste",
  TO: "Tonga",
  PF: "Polynésie française",
  KW: "Koweït",
  MU: "Maurice",
  MD: "Moldova",
  NF: "Norfolk",
  AO: "Angola",
  IO: "Indien",
  CF: "République centrafricaine",
  KN: "Saint-Kitts-et-Nevis",
  HN: "Honduras",
  IT: "Italie",
  RE: "Réunion",
  TG: "Togo",
  VI: "Vierges des États-Unis",
  BO: "Bolivie",
  BF: "Burkina Faso",
  GW: "Guinée-Bissau",
  BD: "Bangladesh",
  CY: "Chypre",
  LB: "Liban",
  MX: "Mexique",
  TM: "Turkménistan",
  LA: "Lao",
  SI: "Slovénie",
  TN: "Tunisie",
  CO: "Colombie",
  CZ: "Tchéquie",
  GN: "Guinée",
  MP: "Mariannes du Nord",
  ST: "Sao Tomé-et-Principe",
  BZ: "Belize",
  GT: "Guatemala",
  IL: "Israël",
  JM: "Jamaïque",
  FK: "Falkland",
  GP: "Guadeloupe",
  IQ: "Iraq",
  MN: "Mongolie",
  BL: "Saint-Barthélemy",
  SJ: "Svalbard et l'Île Jan Mayen",
  TR: "Turquie",
  FJ: "Fidji",
  FI: "Finlande",
  KP: "Corée",
  SO: "Somalie",
  CL: "Chili",
  GI: "Gibraltar",
  PM: "Saint-Pierre-et-Miquelon",
  HR: "Croatie",
  TH: "Thaïlande",
  ET: "Éthiopie",
  SA: "Arabie saoudite",
  UZ: "Ouzbékistan",
  YE: "Yémen",
  BB: "Barbade",
  KY: "Caïmans",
  DO: "dominicaine",
  SV: "El Salvador",
  GU: "Guam",
  NR: "Nauru",
  LT: "Lituanie",
  MT: "Malte",
  MC: "Monaco",
  SR: "Suriname",
  CU: "Cuba",
  DE: "Allemagne",
  LV: "Lettonie",
  VU: "Vanuatu",
  AR: "Argentine",
  SB: "Salomon",
  UM: "Îles mineures éloignées des États-Unis",
  SC: "Seychelles",
  TJ: "Tadjikistan",
  VN: "Viet Nam",
  AT: "Autriche",
  FR: "France",
  NU: "Niue",
  JE: "Jersey",
  SH: "Sainte-Hélène, Ascension et Tristan da Cunha",
  SG: "Singapour",
  AU: "Australie",
  CR: "Costa Rica",
  GR: "Grèce",
  HM: "Heard-et-Îles MacDonald",
  VG: "Vierges britanniques",
  VC: "Saint-Vincent-et-les Grenadines",
  ES: "Espagne",
  KM: "Comores",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Corée",
  CM: "Cameroun",
  TF: "Terres australes françaises",
  JP: "Japon",
  HK: "Hong Kong",
  RO: "Roumanie",
  BG: "Bulgarie",
  LY: "Libye",
  EE: "Estonie",
  SE: "Suède",
  CH: "Suisse",
  NZ: "Nouvelle-Zélande",
  BI: "Burundi",
  CD: "Congo",
  NP: "Népal"
};
