












































import Vue from "vue";
import Component from "vue-class-component";
import ControleDocument from "./ControleDocument.vue";

import { formatDateTime } from "./logic/utils";
import { ControllerDocument } from "./logic/controller";
import { PublicDocument } from "@/shared/logic/types";

const DocumentProps = Vue.extend({
  props: {
    controller: Object as () => ControllerDocument,
    document: Object as () => PublicDocument,
    hideDelete: {
      type: Boolean,
      default: false
    },
    hideUpload: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  }
});

@Component({
  components: { ControleDocument }
})
export default class Document extends DocumentProps {
  get taille() {
    if (!this.document.taille) return "";
    if (this.document.taille < 1_000_000) {
      return (this.document.taille / 1_000).toFixed(2) + " Ko";
    }
    return (this.document.taille / 1_000_000).toFixed(2) + " Mo";
  }

  get dateHeureModif() {
    let d = formatDateTime(this.document.date_heure_modif);
    if (d) {
      d = "déposé le " + d;
    }
    return d;
  }
}
