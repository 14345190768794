


























import Vue from "vue";
import Component from "vue-class-component";
import { formatSecuriteSociale, formatTel } from "@/shared/logic/utils";

const StringFieldProps = Vue.extend({
  props: {
    value: String,
    label: String,
    placeholder: String,
    type: String,
    invalidFeedback: String,
    required: Boolean,
    list: String,
    textarea: Boolean,
    description: String
  },
  model: {
    prop: "value",
    event: "change"
  }
});

@Component({})
export default class StringField extends StringFieldProps {
  get innerPlaceholder() {
    return this.placeholder ? this.placeholder : this.label + "...";
  }

  onInput(v: string) {
    if (this.type == "securite-sociale") {
      v = formatSecuriteSociale(v);
    } else if (this.type == "tel") {
      v = formatTel(v);
    }
    this.$emit("change", v);
  }
}
