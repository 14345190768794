




















































import Vue from "vue";
import Component from "vue-class-component";

import { Output } from "./shared/logic/notifications";
import Logo from "@/shared/Logo.vue";

const BaseAppProps = Vue.extend({
  props: {
    title: String,
    subTitle: {
      type: String,
      default: ""
    },
    output: Object as () => Output | null,
    hideLogo: Boolean,
    hideFooter: Boolean,
    // si oui, affiche les liens intranet et dons
    isAccueil: Boolean
  }
});

@Component({
  components: {
    Logo
  }
})
export default class BaseApp extends BaseAppProps {
  get version() {
    return "version " + process.env.VUE_APP_VERSION;
  }
}
