

























import Vue from "vue";
import Component from "vue-class-component";
import { PropType } from "vue";

const ListeStringsProps = Vue.extend({
  props: {
    items: Array as () => string[] | null,
    formatter: Function as PropType<((v: string) => string) | undefined>,
    validator: Function as PropType<((v: string) => boolean) | undefined>,
    validatorFeedback: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    invalidFeedback: String,
    validated: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "items",
    event: "change"
  }
});

@Component({})
export default class ListeStrings extends ListeStringsProps {
  innerFormatter(v: string): string {
    if (!this.formatter) {
      return v;
    }
    return this.formatter(v);
  }

  onInput(list: string[]) {
    if (JSON.stringify(list) == JSON.stringify(this.items)) {
      return;
    }
    const fmtList = list.map(this.innerFormatter);
    this.$emit("change", fmtList);
  }

  get state() {
    if (this.validated) {
      return this.required ? (this.items || []).length >= 1 : true;
    }
    return null;
  }
}
