// DO NOT EDIT -- autogenerated by structgen
// github.com/benoitkugler/goACVE/server/core/rawdata.String
export type String_ = string;
// github.com/benoitkugler/goACVE/server/core/rawdata.Taille
export type Taille = number;

class DateTag {
  private _: "D" = "D";
}

class TimeTag {
  private _: "T" = "T";
}

// AAAA-MM-YY date format
export type Date_ = string & DateTag;

// ISO date-time string
export type Time = string & TimeTag;

// github.com/benoitkugler/goACVE/server/documents.PublicDocument
export interface PublicDocument {
  id_crypted: string;
  nom_client: String_;
  taille: Taille;
  date_heure_modif: Time;
  url_miniature: string;
  url_download: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.AddBonusDocOut
export interface AddBonusDocOut {
  token: string;
  document: PublicDocument;
}
// github.com/benoitkugler/goACVE/server/directeurs.AddContactJoomeoIn
export interface AddContactJoomeoIn {
  mails: string[] | null;
  send_mail: boolean;
}
// github.com/benoitkugler/goACVE/server/core/utils/joomeo.Contact
export interface Contact {
  contact_id: string;
  mail: string;
  login: string;
  password: string;
}
// github.com/benoitkugler/goACVE/server/core/utils/joomeo.ContactPermission
export type ContactPermission = {
  allowupload: number;
  allowdownload: number;
} & Contact;
// github.com/benoitkugler/goACVE/server/directeurs.AddContactJoomeoOut
export interface AddContactJoomeoOut {
  token: string;
  errors: string[] | null;
  contacts: ContactPermission[] | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.AddDocumentIn
export interface AddDocumentIn {
  id_equipier: number;
  id_contrainte: number;
  description: String_;
}
// github.com/benoitkugler/goACVE/server/directeurs.AddDocumentOut
export interface AddDocumentOut {
  token: string;
  document: PublicDocument;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Ids
export type Ids = number[] | null;
// github.com/benoitkugler/goACVE/server/directeurs.AddGroupeAnimateursIn
export interface AddGroupeAnimateursIn {
  id_groupe: number;
  id_equipiers: Ids;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Euros
export type Euros = number;
// github.com/benoitkugler/goACVE/server/core/rawdata.Bool
export type Bool = boolean;
// github.com/benoitkugler/goACVE/server/core/rawdata.Int
export type Int = number;
// github.com/benoitkugler/goACVE/server/espaceperso.ChampsAideEditables
export interface ChampsAideEditables {
  id_crypted: string;
  valeur: Euros;
  par_jour: Bool;
  nb_jours_max: Int;
  id_participant_crypted: string;
  id_structure_crypted: string;
}
// github.com/benoitkugler/goACVE/server/espaceperso.Aide
export type Aide = {
  valid: Bool;
  valeur_computed: Euros;
  document: PublicDocument;
} & ChampsAideEditables;
// github.com/benoitkugler/goACVE/server/equipier.AjouteDocumentIn
export interface AjouteDocumentIn {
  id_contrainte_crypted: string;
}
// github.com/benoitkugler/goACVE/server/shared.CampMeta
export interface CampMeta {
  id: number;
  label: String_;
  terminated: boolean;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.TrajetBus
export interface TrajetBus {
  rendez_vous: String_;
  prix: Euros;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.BusCamp
export interface BusCamp {
  actif: boolean;
  commentaire: String_;
  aller: TrajetBus;
  retour: TrajetBus;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.MaterielSkiCamp
export interface MaterielSkiCamp {
  actif: boolean;
  prix_acve: Euros;
  prix_loueur: Euros;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionsCamp
export interface OptionsCamp {
  bus: BusCamp;
  materiel_ski: MaterielSkiCamp;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Plage
export interface Plage {
  from: Date_;
  to: Date_;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionSemaineCamp
export interface OptionSemaineCamp {
  plage_1: Plage;
  plage_2: Plage;
  prix_1: Euros;
  prix_2: Euros;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.PrixParStatut
export interface PrixParStatut {
  id: number;
  prix: Euros;
  statut: String_;
  description: String_;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionPrixCamp
export interface OptionPrixCamp {
  active: string;
  semaine: OptionSemaineCamp;
  statut: PrixParStatut[] | null;
  jour: Euros[] | null;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionQuotientFamilial
export type OptionQuotientFamilial = number[];
// github.com/benoitkugler/goACVE/server/core/rawdata.SchemaPaiement
export enum SchemaPaiement {
  SPAcompte = "acompte",
  SPTotal = "total"
}

export const SchemaPaiementLabels: { [key in SchemaPaiement]: string } = {
  [SchemaPaiement.SPAcompte]: "Avec acompte",
  [SchemaPaiement.SPTotal]: "Paiement direct (sans acompte)"
};

// github.com/benoitkugler/goACVE/server/shared.Camp
export type Camp = {
  description: string;
  prix: Euros;
  options: OptionsCamp;
  option_prix: OptionPrixCamp;
  quotient_familial: OptionQuotientFamilial;
  schema_paiement: SchemaPaiement;
  date_debut: Date_;
  date_fin: Date_;
  age_min: Int;
  age_max: Int;
  is_simple: boolean;
  infos: String_;
} & CampMeta;
// github.com/benoitkugler/goACVE/server/espaceperso.CampPlus
export type CampPlus = {
  documents: PublicDocument[] | null;
  mail_directeur: string;
} & Camp;
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionnalBool
export enum OptionnalBool {
  OBNon = -1,
  OBOui = 1,
  OBPeutEtre = 0
}

export const OptionnalBoolLabels: { [key in OptionnalBool]: string } = {
  [OptionnalBool.OBNon]: "Non",
  [OptionnalBool.OBOui]: "Oui",
  [OptionnalBool.OBPeutEtre]: "Peut-être"
};

// github.com/benoitkugler/goACVE/server/equipier.CharteIn
export interface CharteIn {
  charte: OptionnalBool;
}
// github.com/benoitkugler/goACVE/server/directeurs.ResultatRecherche
export interface ResultatRecherche {
  id: number;
  nom: String_;
  prenom: String_;
  date_naissance: string;
  pertinence: number;
}
// github.com/benoitkugler/goACVE/server/directeurs.ChercheSimilaireOut
export interface ChercheSimilaireOut {
  token: string;
  propositions: ResultatRecherche[] | null;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Tels
export type Tels = string[] | null;
// github.com/benoitkugler/goACVE/server/core/rawdata.Pays
export type Pays = string;
// github.com/benoitkugler/goACVE/server/espaceperso.Coordonnees
export interface Coordonnees {
  mail: String_;
  tels: Tels;
  adresse: String_;
  code_postal: String_;
  ville: String_;
  pays: Pays;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Sexe
export enum Sexe {
  SAucun = "",
  SFemme = "F",
  SHomme = "M"
}

export const SexeLabels: { [key in Sexe]: string } = {
  [Sexe.SAucun]: "-",
  [Sexe.SFemme]: "Femme",
  [Sexe.SHomme]: "Homme"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.Destinataire
export interface Destinataire {
  nom_prenom: String_;
  sexe: Sexe;
  adresse: String_;
  code_postal: String_;
  ville: String_;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.DestinatairesOptionnels
export type DestinatairesOptionnels = Destinataire[] | null;
// github.com/benoitkugler/goACVE/server/espaceperso.Responsable
export type Responsable = {
  prenom: string;
  sexe: Sexe;
  nom_prenom: string;
  copies_mails: string[] | null;
  securite_sociale: String_;
  destinataires_optionnels: DestinatairesOptionnels;
} & Coordonnees;
// github.com/benoitkugler/goACVE/server/core/rawdata.Maladies
export interface Maladies {
  rubeole: boolean;
  varicelle: boolean;
  angine: boolean;
  oreillons: boolean;
  scarlatine: boolean;
  coqueluche: boolean;
  otite: boolean;
  rougeole: boolean;
  rhumatisme: boolean;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Allergies
export interface Allergies {
  asthme: boolean;
  alimentaires: boolean;
  medicamenteuses: boolean;
  autres: string;
  conduite_a_tenir: string;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Medecin
export interface Medecin {
  nom: string;
  tel: string;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.FicheSanitaire
export interface FicheSanitaire {
  traitement_medical: boolean;
  maladies: Maladies;
  allergies: Allergies;
  difficultes_sante: string;
  recommandations: string;
  handicap: boolean;
  tel: string;
  medecin: Medecin;
  last_modif: Time;
  mails: string[] | null;
}
// github.com/benoitkugler/goACVE/server/espaceperso.LockableFicheSanitaire
export type LockableFicheSanitaire = {
  locked: boolean;
} & FicheSanitaire;
// github.com/benoitkugler/goACVE/server/core/rawdata.BuiltinContrainte
export enum BuiltinContrainte {
  CAutre = "autre",
  CBafa = "bafa",
  CBafaEquiv = "bafa_equiv",
  CBafd = "bafd",
  CBafdEquiv = "bafd_equiv",
  CCarteId = "carte_id",
  CCarteVitale = "carte_vitale",
  CCertMedCuisine = "cert_med_cuisine",
  CHaccp = "haccp",
  CInvalide = "",
  CPermis = "permis",
  CSb = "sb",
  CScolarite = "scolarite",
  CSecour = "secour",
  CTestNautique = "test_nautique",
  CVaccin = "vaccin"
}

export const BuiltinContrainteLabels: { [key in BuiltinContrainte]: string } = {
  [BuiltinContrainte.CAutre]: "Autre",
  [BuiltinContrainte.CBafa]: "BAFA",
  [BuiltinContrainte.CBafaEquiv]: "Equivalent BAFA",
  [BuiltinContrainte.CBafd]: "BAFD",
  [BuiltinContrainte.CBafdEquiv]: "Equivalent BAFD",
  [BuiltinContrainte.CCarteId]: "Carte d''identité/Passeport",
  [BuiltinContrainte.CCarteVitale]: "Carte Vitale",
  [BuiltinContrainte.CCertMedCuisine]: "Certificat médical Cuisine",
  [BuiltinContrainte.CHaccp]: "Cuisine (HACCP)",
  [BuiltinContrainte.CInvalide]: "-",
  [BuiltinContrainte.CPermis]: "Permis de conduire",
  [BuiltinContrainte.CSb]: "Surveillant de baignade",
  [BuiltinContrainte.CScolarite]: "Certificat de scolarité",
  [BuiltinContrainte.CSecour]: "Secourisme (PSC1 - AFPS)",
  [BuiltinContrainte.CTestNautique]: "Test nautique",
  [BuiltinContrainte.CVaccin]: "Vaccin"
};

// github.com/benoitkugler/goACVE/server/documents.PublicContrainte
export interface PublicContrainte {
  id_crypted: string;
  document: PublicDocument;
  nom: String_;
  description: String_;
  max_docs: number;
  jours_valide: number;
  builtin: BuiltinContrainte;
}
// github.com/benoitkugler/goACVE/server/documents.ContrainteDocuments
export interface ContrainteDocuments {
  contrainte: PublicContrainte;
  docs: PublicDocument[] | null;
}
// github.com/benoitkugler/goACVE/server/espaceperso.ContrainteWithOrigine
export type ContrainteWithOrigine = {
  origine: Ids;
} & ContrainteDocuments;
// github.com/benoitkugler/goACVE/server/espaceperso.Personne
export interface Personne {
  id_crypted: string;
  prenom: string;
  nom_prenom: string;
  sexe: Sexe;
  date_naissance: Date_;
  fiche_sanitaire: LockableFicheSanitaire;
  vaccins: PublicDocument[] | null;
  documents: ContrainteWithOrigine[] | null;
  is_temporaire: Bool;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.StatutAttente
export enum StatutAttente {
  Attente = 1,
  AttenteReponse = 2,
  Inscrit = 0,
  Refuse = 3
}

export const StatutAttenteLabels: { [key in StatutAttente]: string } = {
  [StatutAttente.Attente]: "Liste d'attente",
  [StatutAttente.AttenteReponse]: "Attente de confirmation",
  [StatutAttente.Inscrit]: "Inscrit",
  [StatutAttente.Refuse]: "Refusé"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.ListeAttente
export interface ListeAttente {
  statut: StatutAttente;
  raison: string;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.HintsAttente
export interface HintsAttente {
  AgeMin: StatutAttente;
  AgeMax: StatutAttente;
  EquilibreGF: StatutAttente;
  Place: StatutAttente;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Bus
export enum Bus {
  BAller = "aller",
  BAllerRetour = "aller_retour",
  BAucun = "",
  BRetour = "retour"
}

export const BusLabels: { [key in Bus]: string } = {
  [Bus.BAller]: "Aller",
  [Bus.BAllerRetour]: "Aller-Retour",
  [Bus.BAucun]: "-",
  [Bus.BRetour]: "Retour"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.MaterielSki
export interface MaterielSki {
  need: string;
  mode: string;
  casque: boolean;
  poids: number;
  taille: number;
  pointure: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionsParticipant
export interface OptionsParticipant {
  bus: Bus;
  materiel_ski: MaterielSki;
}
// github.com/benoitkugler/goACVE/server/espaceperso.Participant
export interface Participant {
  id: number;
  id_crypted: string;
  id_personne_crypted: string;
  id_camp: number;
  liste_attente: ListeAttente;
  hints_attente: HintsAttente;
  is_fiche_sanitaire_up_to_date: boolean;
  options: OptionsParticipant;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.HexColor
export type HexColor = string;
// github.com/benoitkugler/goACVE/server/core/rawdata.MessageKind
export enum MessageKind {
  MAccuseReception = 3,
  MAttestationPresence = 7,
  MCentre = 2,
  MDocuments = 5,
  MFacture = 4,
  MFactureAcquittee = 6,
  MInscription = 9,
  MPaiement = 11,
  MPlaceLiberee = 10,
  MResponsable = 1,
  MSondage = 8,
  MSupprime = 0
}

export const MessageKindLabels: { [key in MessageKind]: string } = {
  [MessageKind.MAccuseReception]: "Inscription reçue",
  [MessageKind.MAttestationPresence]: "Attestation de présence",
  [MessageKind.MCentre]: "Message du centre",
  [MessageKind.MDocuments]: "Document des séjours",
  [MessageKind.MFacture]: "Facture",
  [MessageKind.MFactureAcquittee]: "Facture acquittée",
  [MessageKind.MInscription]: "Moment d'inscription",
  [MessageKind.MPaiement]: "",
  [MessageKind.MPlaceLiberee]: "Place libérée",
  [MessageKind.MResponsable]: "Message",
  [MessageKind.MSondage]: "Avis sur le séjour",
  [MessageKind.MSupprime]: "Message supprimé"
};

// github.com/benoitkugler/goACVE/server/core/datamodel.MessageContenu
export type MessageContenu = any;
// github.com/benoitkugler/goACVE/server/core/datamodel.PseudoMessage
export interface PseudoMessage {
  id: number;
  id_facture: number;
  label: string;
  color: HexColor;
  created: Time;
  modified: Time;
  vu: boolean;
  is_viewable: boolean;
  is_editable: boolean;
  is_deletable: boolean;
  kind: MessageKind;
  contenu: MessageContenu;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Satisfaction
export enum Satisfaction {
  SDécevant = 1,
  SMoyen = 2,
  SSatisfaisant = 3,
  STressatisfaisant = 4,
  SVide = 0
}

export const SatisfactionLabels: { [key in Satisfaction]: string } = {
  [Satisfaction.SDécevant]: "Décevant",
  [Satisfaction.SMoyen]: "Moyen",
  [Satisfaction.SSatisfaisant]: "Satisfaisant",
  [Satisfaction.STressatisfaisant]: "Très satisfaisant",
  [Satisfaction.SVide]: "-"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.RepSondage
export interface RepSondage {
  infos_avant_sejour: Satisfaction;
  infos_pendant_sejour: Satisfaction;
  hebergement: Satisfaction;
  activites: Satisfaction;
  theme: Satisfaction;
  nourriture: Satisfaction;
  hygiene: Satisfaction;
  ambiance: Satisfaction;
  ressenti: Satisfaction;
  message_enfant: String_;
  message_responsable: String_;
}
// github.com/benoitkugler/goACVE/server/espaceperso.PublicSondage
export type PublicSondage = {
  id_crypted: string;
  id_camp: number;
  modified: Time;
} & RepSondage;
// github.com/benoitkugler/goACVE/server/espaceperso.ContentEspacePerso
export interface ContentEspacePerso {
  responsable: Responsable;
  personnes: { [key: string]: Personne } | null;
  participants: Participant[] | null;
  camps: { [key: number]: CampPlus } | null;
  messages: PseudoMessage[] | null;
  sondages: { [key: number]: PublicSondage } | null;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Distribution
export enum Distribution {
  DEspacePerso = 0,
  DMail = 1,
  DMailAndDownload = 2
}

export const DistributionLabels: { [key in Distribution]: string } = {
  [Distribution.DEspacePerso]: "Téléchargée depuis l'espace de suivi",
  [Distribution.DMail]: "Notifiée par courriel",
  [Distribution.DMailAndDownload]: "Téléchargée après notification"
};

// github.com/benoitkugler/goACVE/server/core/datamodel.ContenuAttestation
export interface ContenuAttestation {
  distribution: Distribution;
}
// github.com/benoitkugler/goACVE/server/core/datamodel.ContenuDocument
export interface ContenuDocument {
  id_message: number;
  id_camp: number;
}
// github.com/benoitkugler/goACVE/server/core/datamodel.ContenuPerso
export type ContenuPerso = string;
// github.com/benoitkugler/goACVE/server/core/datamodel.ContenuPlaceLiberee
export interface ContenuPlaceLiberee {
  id_message: number;
  id_participant: number;
}
// github.com/benoitkugler/goACVE/server/core/datamodel.ContenuSondage
export interface ContenuSondage {
  id_message: number;
  id_camp: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionnalId
export interface OptionnalId {
  Int64: number;
  Valid: boolean;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Contrainte
export interface Contrainte {
  id: number;
  id_personne: OptionnalId;
  id_document: OptionnalId;
  builtin: BuiltinContrainte;
  nom: String_;
  description: String_;
  max_docs: number;
  jours_valide: number;
}
// github.com/benoitkugler/goACVE/server/directeurs.ContrainteComplete
export type ContrainteComplete = {
  document: PublicDocument;
} & Contrainte;
// github.com/benoitkugler/goACVE/server/equipier.ContrainteEquipier
export type ContrainteEquipier = {
  optionnel: boolean;
} & ContrainteDocuments;
// github.com/benoitkugler/goACVE/server/core/rawdata.Role
export enum Role {
  RAdjoint = "_adjoint",
  RAideAnimation = "_aideanim",
  RAnimation = "_anim",
  RAutre = "_autre",
  RBabysiter = "_babysiter",
  RChauffeur = "_chauffeur",
  RCuis = "_cuis",
  RDirecteur = "_dir",
  RFactotum = "_factotum",
  RInfirm = "_infirm",
  RIntend = "_intend",
  RLing = "_ling",
  RMen = "_men"
}

export const RoleLabels: { [key in Role]: string } = {
  [Role.RAdjoint]: "Adjoint",
  [Role.RAideAnimation]: "Aide-animateur",
  [Role.RAnimation]: "Animation",
  [Role.RAutre]: "Autre",
  [Role.RBabysiter]: "Baby-sitter",
  [Role.RChauffeur]: "Chauffeur",
  [Role.RCuis]: "Cuisine",
  [Role.RDirecteur]: "Direction",
  [Role.RFactotum]: "Factotum",
  [Role.RInfirm]: "Assistant sanitaire",
  [Role.RIntend]: "Intendance",
  [Role.RLing]: "Lingerie",
  [Role.RMen]: "Ménage"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.Roles
export type Roles = Role[] | null;
// github.com/benoitkugler/goACVE/server/core/rawdata/matching.PatternsSimilarite
export interface PatternsSimilarite {
  nom: String_;
  prenom: String_;
  sexe: Sexe;
  date_naissance: Date_;
  mail: String_;
  nom_jeune_fille: String_;
}
// github.com/benoitkugler/goACVE/server/directeurs.CreateEquipierIn
export interface CreateEquipierIn {
  rattache: boolean;
  roles: Roles;
  id_personne: number;
  personne: PatternsSimilarite;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Departement
export type Departement = string;
// github.com/benoitkugler/goACVE/server/core/rawdata.BasePersonne
export interface BasePersonne {
  nom: String_;
  nom_jeune_fille: String_;
  prenom: String_;
  date_naissance: Date_;
  ville_naissance: String_;
  departement_naissance: Departement;
  sexe: Sexe;
  tels: Tels;
  mail: String_;
  adresse: String_;
  code_postal: String_;
  ville: String_;
  pays: Pays;
  securite_sociale: String_;
  profession: String_;
  etudiant: Bool;
  fonctionnaire: Bool;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Diplome
export enum Diplome {
  DAgreg = "agreg",
  DAssSociale = "ass_sociale",
  DAucun = "",
  DBafa = "bafa",
  DBafaStag = "bafa_stag",
  DBafd = "bafd",
  DBafdStag = "bafd_stag",
  DBapaat = "bapaat",
  DBeatep = "beatep",
  DBjeps = "bjeps",
  DCap = "cap",
  DDeug = "deug",
  DDut = "dut",
  DEducSpe = "educ_spe",
  DEje = "eje",
  DInstit = "instit",
  DMonEduc = "mon_educ",
  DProf = "prof",
  DStaps = "staps",
  DZzautre = "zzautre"
}

export const DiplomeLabels: { [key in Diplome]: string } = {
  [Diplome.DAgreg]: "Agrégé",
  [Diplome.DAssSociale]: "Assitante Sociale",
  [Diplome.DAucun]: "Aucun",
  [Diplome.DBafa]: "BAFA Titulaire",
  [Diplome.DBafaStag]: "BAFA Stagiaire",
  [Diplome.DBafd]: "BAFD titulaire",
  [Diplome.DBafdStag]: "BAFD stagiaire",
  [Diplome.DBapaat]: "BAPAAT",
  [Diplome.DBeatep]: "BEATEP",
  [Diplome.DBjeps]: "BPJEPS",
  [Diplome.DCap]: "CAP petit enfance",
  [Diplome.DDeug]: "DEUG",
  [Diplome.DDut]: "DUT carrière sociale",
  [Diplome.DEducSpe]: "Educ. spé.",
  [Diplome.DEje]: "EJE",
  [Diplome.DInstit]: "Professeur des écoles",
  [Diplome.DMonEduc]: "Moniteur educateur",
  [Diplome.DProf]: "Enseignant du secondaire",
  [Diplome.DStaps]: "STAPS",
  [Diplome.DZzautre]: "AUTRE"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.Approfondissement
export enum Approfondissement {
  AAucun = "",
  AAutre = "autre",
  ACanoe = "canoe",
  AMoto = "moto",
  ASb = "sb",
  AVoile = "voile"
}

export const ApprofondissementLabels: { [key in Approfondissement]: string } = {
  [Approfondissement.AAucun]: "Non effectué",
  [Approfondissement.AAutre]: "Approfondissement",
  [Approfondissement.ACanoe]: "Canoë - Kayak",
  [Approfondissement.AMoto]: "Loisirs motocyclistes",
  [Approfondissement.ASb]: "Surveillant de baignade",
  [Approfondissement.AVoile]: "Voile"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.OptionnalPlage
export type OptionnalPlage = {
  active: boolean;
} & Plage;
// github.com/benoitkugler/goACVE/server/core/rawdata.InvitationEquipier
export type InvitationEquipier = number;
// github.com/benoitkugler/goACVE/server/shared.BaseEquipier
export type BaseEquipier = {
  is_temporaire: Bool;
  diplome: Diplome;
  appro: Approfondissement;
  presence: OptionnalPlage;
  invitation_equipier: InvitationEquipier;
  charte: OptionnalBool;
} & BasePersonne;
// github.com/benoitkugler/goACVE/server/equipier.EquipierEquipier
export type EquipierEquipier = {} & BaseEquipier;
// github.com/benoitkugler/goACVE/server/equipier.DataEquipier
export interface DataEquipier {
  equipier: EquipierEquipier;
  camp: Camp;
  pieces: ContrainteEquipier[] | null;
}
// github.com/lib/pq.StringArray
export type StringArray = string[] | null;
// github.com/benoitkugler/goACVE/server/core/rawdata.IdentificationId
export interface IdentificationId {
  valid: boolean;
  id: number;
  crypted: string;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ResponsableLegal
export interface ResponsableLegal {
  lienid: IdentificationId;
  nom: String_;
  prenom: String_;
  sexe: Sexe;
  mail: String_;
  adresse: String_;
  code_postal: String_;
  ville: String_;
  tels: Tels;
  date_naissance: Date_;
  pays: Pays;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Semaine
export enum Semaine {
  SComplet = "",
  SSe1 = "1",
  SSe2 = "2"
}

export const SemaineLabels: { [key in Semaine]: string } = {
  [Semaine.SComplet]: "Camp complet",
  [Semaine.SSe1]: "Semaine 1",
  [Semaine.SSe2]: "Semaine 2"
};

// github.com/benoitkugler/goACVE/server/core/rawdata.Jours
export type Jours = number[] | null;
// github.com/benoitkugler/goACVE/server/core/rawdata.OptionPrixParticipant
export interface OptionPrixParticipant {
  semaine: Semaine;
  statut: number;
  jour: Jours;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ParticipantInscription
export interface ParticipantInscription {
  lienid: IdentificationId;
  nom: String_;
  prenom: String_;
  date_naissance: Date_;
  sexe: Sexe;
  id_camp: number;
  options: OptionsParticipant;
  options_prix: OptionPrixParticipant;
  quotient_familial: Int;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ParticipantInscriptions
export type ParticipantInscriptions = ParticipantInscription[] | null;
// github.com/benoitkugler/goACVE/server/core/rawdata.Inscription
export interface Inscription {
  id: number;
  info: String_;
  date_heure: Time;
  copies_mails: StringArray;
  responsable: ResponsableLegal;
  participants: ParticipantInscriptions;
  partage_adresses_ok: boolean;
}
// github.com/benoitkugler/goACVE/server/inscriptions.DataInscription
export interface DataInscription {
  camps: Camp[] | null;
  preselect_camp: string;
  initial_inscription: Inscription;
}
// github.com/benoitkugler/goACVE/server/equipier.DatasJoomeo
export interface DatasJoomeo {
  space_url: string;
  login: string;
  password: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.DeleteContactOut
export interface DeleteContactOut {
  token: string;
  contacts: ContactPermission[] | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.DeleteContrainteOut
export interface DeleteContrainteOut {
  token: string;
  nb_documents: number;
}
// github.com/benoitkugler/goACVE/server/directeurs.DeleteGroupeAnimateurIn
export interface DeleteGroupeAnimateurIn {
  id_groupe: number;
  id_equipier: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.GroupeContrainte
export interface GroupeContrainte {
  id_groupe: number;
  id_contrainte: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.GroupeContraintes
export type GroupeContraintes = GroupeContrainte[] | null;
// github.com/benoitkugler/goACVE/server/core/rawdata.CampContrainte
export interface CampContrainte {
  id_camp: number;
  id_contrainte: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.CampContraintes
export type CampContraintes = CampContrainte[] | null;
// github.com/benoitkugler/goACVE/server/directeurs.DemandeContraintesIn
export interface DemandeContraintesIn {
  groupe_contraintes: GroupeContraintes;
  camp_contraintes: CampContraintes;
}
// github.com/benoitkugler/goACVE/server/directeurs.DetailsWritable
export interface DetailsWritable {
  nom: String_;
  lieu: String_;
  nb_places: Int;
  need_equilibre_gf: Bool;
  materiel_ski: MaterielSkiCamp;
}
// github.com/benoitkugler/goACVE/server/directeurs.Details
export type Details = {
  bus: BusCamp;
  date_debut: Date_;
  date_fin: Date_;
} & DetailsWritable;
// github.com/benoitkugler/goACVE/server/directeurs.DetailsDetailsOutput
export interface DetailsDetailsOutput {
  token: string;
  details: Details;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Envois
export interface Envois {
  __locked__: boolean;
  lettre_directeur: boolean;
  liste_vetements: boolean;
  liste_participants: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.DetailsEnvoisOut
export interface DetailsEnvoisOut {
  token: string;
  envois: Envois;
  pieces_jointes: PublicDocument[] | null;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Contraintes
export type Contraintes = { [key: number]: Contrainte } | null;
// github.com/benoitkugler/goACVE/server/core/rawdata.EquipierContrainte
export interface EquipierContrainte {
  id_equipier: number;
  id_contrainte: number;
  optionnel: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.EquipierDocuments
export interface EquipierDocuments {
  contraintes: EquipierContrainte[] | null;
  id_equipier: number;
  nom_prenom: string;
  roles: Roles;
  documents: { [key: number]: PublicDocument[] | null } | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.Pieces
export interface Pieces {
  contraintes: Contraintes;
  documents: EquipierDocuments[] | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.DocumentsEquipeOut
export interface DocumentsEquipeOut {
  token: string;
  pieces: Pieces;
}
// github.com/benoitkugler/goACVE/server/directeurs.modeDownloadFicheSanitaire
export type modeDownloadFicheSanitaire = string;
// github.com/benoitkugler/goACVE/server/directeurs.DownloadFicheSanitaireIn
export interface DownloadFicheSanitaireIn {
  mode: modeDownloadFicheSanitaire;
  id_participant: number;
  tri_groupe: boolean;
  only_mineurs: boolean;
}
// github.com/benoitkugler/goACVE/server/espaceperso.EditMessage
export interface EditMessage {
  id: number;
  contenu: String_;
}
// github.com/benoitkugler/goACVE/server/inscriptions.EnregistreInscriptionOut
export interface EnregistreInscriptionOut {
  check_mail: boolean;
  url_espace_perso: string;
}
// github.com/benoitkugler/goACVE/server/espaceperso.Structureaide
export interface Structureaide {
  id_crypted: string;
  nom: String_;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ModePaiment
export enum ModePaiment {
  MPAncv = "ancv",
  MPAucun = "",
  MPCarte = "cb",
  MPCheque = "cheque",
  MPEspece = "esp",
  MPHelloasso = "helloasso",
  MPVirement = "vir"
}

export const ModePaimentLabels: { [key in ModePaiment]: string } = {
  [ModePaiment.MPAncv]: "ANCV",
  [ModePaiment.MPAucun]: "-",
  [ModePaiment.MPCarte]: "Carte bancaire",
  [ModePaiment.MPCheque]: "Chèque",
  [ModePaiment.MPEspece]: "Espèces",
  [ModePaiment.MPHelloasso]: "Hello Asso",
  [ModePaiment.MPVirement]: "Virement"
};

// github.com/benoitkugler/goACVE/server/espaceperso.Paiement
export interface Paiement {
  label_payeur: String_;
  valeur: Euros;
  is_invalide: Bool;
  date_reglement: Time;
  mode_paiement: ModePaiment;
  is_remboursement: Bool;
}
// github.com/benoitkugler/goACVE/server/espaceperso.etatFinancier
export interface etatFinancier {
  total_sejours: Euros;
  total_aides: Euros;
  total_paiements: Euros;
  total_restant: Euros;
}
// github.com/benoitkugler/goACVE/server/espaceperso.Finances
export interface Finances {
  structure_aides: { [key: string]: Structureaide } | null;
  label_virement: string;
  aides: Aide[] | null;
  paiements: Paiement[] | null;
  etat_financier: etatFinancier;
  paiement_en_ligne: boolean;
}
// github.com/benoitkugler/goACVE/server/espaceperso.InCreateDocument
export interface InCreateDocument {
  id_personne: string;
  id_contrainte: string;
}
// github.com/benoitkugler/goACVE/server/espaceperso.InFicheSanitaire
export interface InFicheSanitaire {
  id_crypted: string;
  fiche_sanitaire: FicheSanitaire;
  securite_sociale: string;
}
// github.com/benoitkugler/goACVE/server/inscriptions.InscriptionSimple
export interface InscriptionSimple {
  nom: String_;
  prenom: String_;
  sexe: Sexe;
  date_naissance: Date_;
  mail: String_;
  tel: string;
  info: String_;
}
// github.com/benoitkugler/goACVE/server/directeurs.formulaireEquipier
export type formulaireEquipier = string;
// github.com/benoitkugler/goACVE/server/directeurs.InviteFormulaireIn
export interface InviteFormulaireIn {
  mode: formulaireEquipier;
  id_participant: number;
}
// github.com/benoitkugler/goACVE/server/core/utils/joomeo.Album
export interface Album {
  album_id: string;
  label: string;
  folder_id: string;
  nb_files: number;
  date: Date_;
}
// github.com/benoitkugler/goACVE/server/espaceperso.JoomeoOutput
export interface JoomeoOutput {
  url_space: string;
  loggin: string;
  password: string;
  albums: Album[] | null;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Lettredirecteur
export interface Lettredirecteur {
  id_camp: number;
  html: string;
  use_coord_centre: boolean;
  show_adresse_postale: boolean;
  color_coord: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.LettreDirecteurOut
export interface LettreDirecteurOut {
  token: string;
  lettre: Lettredirecteur;
  document: PublicDocument;
}
// github.com/benoitkugler/goACVE/server/directeurs.LieDocumentOut
export interface LieDocumentOut {
  token: string;
  document: PublicDocument;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Vetement
export interface Vetement {
  quantite: number;
  description: string;
  obligatoire: boolean;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ListeVetements
export interface ListeVetements {
  liste: Vetement[] | null;
  complement: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.ListeVetementsOut
export interface ListeVetementsOut {
  token: string;
  liste: ListeVetements;
}
// github.com/benoitkugler/goACVE/server/directeurs.LoadContraintesOut
export interface LoadContraintesOut {
  token: string;
  contraintes: { [key: number]: ContrainteComplete } | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.EquipierDirecteur
export type EquipierDirecteur = {
  id: number;
  lien_formulaire: string;
  roles: Roles;
  is_sb: Bool;
} & BaseEquipier;
// github.com/benoitkugler/goACVE/server/directeurs.LoadEquipiersOut
export interface LoadEquipiersOut {
  token: string;
  equipe: EquipierDirecteur[] | null;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ParticipantEquipier
export interface ParticipantEquipier {
  id_participant: number;
  id_equipier: number;
  id_groupe: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.ParticipantEquipiers
export type ParticipantEquipiers = ParticipantEquipier[] | null;
// github.com/benoitkugler/goACVE/server/directeurs.UpdateInsritsAnimateurOut
export interface UpdateInsritsAnimateurOut {
  token: string;
  participant_equipiers: ParticipantEquipiers;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.GroupeEquipier
export interface GroupeEquipier {
  id_groupe: number;
  id_equipier: number;
  id_camp: number;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.GroupeEquipiers
export type GroupeEquipiers = GroupeEquipier[] | null;
// github.com/benoitkugler/goACVE/server/directeurs.LoadGroupeAnimateursOut
export type LoadGroupeAnimateursOut = {
  groupe_equipiers: GroupeEquipiers;
} & UpdateInsritsAnimateurOut;
// github.com/benoitkugler/goACVE/server/core/rawdata.Groupe
export interface Groupe {
  id: number;
  id_camp: number;
  nom: String_;
  plage: Plage;
  couleur: string;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Groupes
export type Groupes = { [key: number]: Groupe } | null;
// github.com/benoitkugler/goACVE/server/directeurs.LoadGroupesOut
export interface LoadGroupesOut {
  token: string;
  groupes: Groupes;
  camp_contraintes: CampContraintes;
  groupe_contraintes: GroupeContraintes;
}
// github.com/benoitkugler/goACVE/server/directeurs.InscritWritable
export interface InscritWritable {
  id_groupe: OptionnalId;
  options: OptionsParticipant;
  mail: string;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.Completion
export enum Completion {
  Complete = 3,
  EnCours = 2,
  Invalide = 0,
  NonCommencee = 1
}

export const CompletionLabels: { [key in Completion]: string } = {
  [Completion.Complete]: "Complet",
  [Completion.EnCours]: "En cours",
  [Completion.Invalide]: "-",
  [Completion.NonCommencee]: "En attente"
};

// github.com/benoitkugler/goACVE/server/directeurs.ResumeMessage
export interface ResumeMessage {
  id: number;
  label: string;
  created: Time;
  contenu: string;
  kind: MessageKind;
  vu: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.Inscrit
export type Inscrit = {
  id: number;
  is_attente: boolean;
  has_anniversaire: boolean;
  age_debut_camp: number;
  responsable: Responsable;
  nom: string;
  prenom: string;
  sexe: Sexe;
  date_naissance: Date_;
  options_prix: OptionPrixParticipant;
  fiche_sanitaire: FicheSanitaire;
  vaccins: PublicDocument[] | null;
  is_fiche_sanitaire_up_to_date: OptionnalBool;
  lien_fiche_sanitaire: string;
  info: String_;
} & InscritWritable;
// github.com/benoitkugler/goACVE/server/directeurs.LoadInscritsOut
export interface LoadInscritsOut {
  token: string;
  inscrits: Inscrit[] | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.JoomeoData
export interface JoomeoData {
  space_url: string;
  meta: Album;
  contacts: ContactPermission[] | null;
  mails_inscrits: string[] | null;
  mails_responsables: string[] | null;
  mails_equipiers: string[] | null;
}
// github.com/benoitkugler/goACVE/server/directeurs.LoadJoomeoOut
export type LoadJoomeoOut = {
  token: string;
} & JoomeoData;
// github.com/benoitkugler/goACVE/server/core/rawdata.Sondage
export type Sondage = {
  id: number;
  id_camp: number;
  id_facture: number;
  modified: Time;
} & RepSondage;
// github.com/benoitkugler/goACVE/server/directeurs.SondageParts
export type SondageParts = {
  participants: String_[] | null;
  responsable: String_;
  mail: String_;
} & Sondage;
// github.com/benoitkugler/goACVE/server/directeurs.LoadSondagesOut
export interface LoadSondagesOut {
  token: string;
  sondages: SondageParts[] | null;
}
// github.com/benoitkugler/goACVE/server/vote.LockVote
export interface LockVote {
  id_vote: number;
  is_locked: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.LogginIn
export interface LogginIn {
  id_camp: number;
  password: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.LogginOut
export interface LogginOut {
  token: string;
  valide: boolean;
  camp: Camp;
  lien_compta: string;
}
// github.com/benoitkugler/goACVE/server/vote.Participation
export interface Participation {
  total: number;
  actuels: number;
  last: Time;
}
// github.com/benoitkugler/goACVE/server/vote.Membre
export interface Membre {
  nom_prenom: string;
  mail: String_;
  lien: string;
  participation: Participation;
}
// github.com/benoitkugler/goACVE/server/espaceperso.MetaEspacePerso
export interface MetaEspacePerso {
  contrainte_vaccin: PublicContrainte;
  update_limitation: number;
  mail_centre_inscription: string;
}
// github.com/benoitkugler/goACVE/server/inscriptions.MetaInscription
export interface MetaInscription {
  is_complet: boolean;
  label_camp_simple: string;
}
// github.com/benoitkugler/goACVE/server/vote.MetaPersonne
export interface MetaPersonne {
  prenom_nom: string;
}
// github.com/benoitkugler/goACVE/server/shared.NotificationError
export interface NotificationError {
  content: string;
}
// github.com/benoitkugler/goACVE/server/shared.NotificationSuccess
export interface NotificationSuccess {
  title: string;
  sub_title: string;
  content: string;
}
// github.com/benoitkugler/goACVE/server/shared.NotificationContent
export interface NotificationContent {
  is_error: boolean;
  error: NotificationError;
  success: NotificationSuccess;
}
// github.com/benoitkugler/goACVE/server/espaceperso.OptionsPaiementEnLigneIn
export interface OptionsPaiementEnLigneIn {
  montant: Euros;
  useAltMail: boolean;
}
// github.com/benoitkugler/goACVE/server/espaceperso.OptionsPaiementEnLigneOut
export interface OptionsPaiementEnLigneOut {
  session_id: string;
}
// github.com/benoitkugler/goACVE/server/espaceperso.OutFicheSanitaire
export interface OutFicheSanitaire {
  fiche_sanitaire: LockableFicheSanitaire;
  securite_sociale: String_;
}
// github.com/benoitkugler/goACVE/server/core/apiserver.VersionInfos
export interface VersionInfos {
  version: string;
  hash: number[] | null;
  nb_files: number;
}
// github.com/benoitkugler/goACVE/server/logiciel.Versions
export interface Versions {
  windows: VersionInfos;
  darwin: VersionInfos;
  linux: VersionInfos;
}
// github.com/benoitkugler/goACVE/server/logiciel.OutLogiciel
export interface OutLogiciel {
  versions: Versions;
  wrong_password: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.PreviewDocumentsParticipantsOut
export interface PreviewDocumentsParticipantsOut {
  nb_demandes: number;
  nb_recus: number;
}
// github.com/benoitkugler/goACVE/server/directeurs.RenvoieLienFicheSanitaireIn
export interface RenvoieLienFicheSanitaireIn {
  mail: string;
  mails_copies: string[] | null;
  url: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.RenvoieLienJoomeoIn
export interface RenvoieLienJoomeoIn {
  mail: string;
  url: string;
  login: string;
  password: string;
}
// github.com/benoitkugler/goACVE/server/misc.SearchMailIn
export interface SearchMailIn {
  Mail: string;
}
// github.com/benoitkugler/goACVE/server/misc.SearchMailOut
export interface SearchMailOut {
  Found: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.SetContactUploaderIn
export interface SetContactUploaderIn {
  contactid: string;
}
// github.com/benoitkugler/goACVE/server/directeurs.SetContactUploaderOut
export interface SetContactUploaderOut {
  token: string;
  contacts: ContactPermission[] | null;
  err_super_contact: string;
}
// github.com/benoitkugler/goACVE/server/sondages.SondageDetails
export type SondageDetails = {
  camp: string;
  responsable: string;
  mail: string;
} & Sondage;
// github.com/benoitkugler/goACVE/server/directeurs.UpdateContrainteEquipierIn
export interface UpdateContrainteEquipierIn {
  id_equipier: number;
  id_contrainte: number;
  demande: OptionnalBool;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdateContrainteOut
export interface UpdateContrainteOut {
  token: string;
  contrainte: Contrainte;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdateGroupeOut
export interface UpdateGroupeOut {
  token: string;
  groupe: Groupe;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdateInscritIn
export interface UpdateInscritIn {
  id: number;
  modifications: InscritWritable;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdateInscritsAnimateurIn
export interface UpdateInscritsAnimateurIn {
  id_groupe: number;
  id_animateur: OptionnalId;
  id_inscrits: Ids;
}
// github.com/benoitkugler/goACVE/server/core/rawdata.IdParticipant
export type IdParticipant = number;
// github.com/benoitkugler/goACVE/server/directeurs.UpdateJoursPresenceIn
export interface UpdateJoursPresenceIn {
  id_participant: IdParticipant;
  jours: Jours;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdateMessageViewIn
export interface UpdateMessageViewIn {
  id_message: number;
  vu: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdatePlageIn
export interface UpdatePlageIn {
  groupes: Groupes;
  erase: boolean;
}
// github.com/benoitkugler/goACVE/server/directeurs.UpdatePlageOut
export interface UpdatePlageOut {
  token: string;
  nb_participants: number;
}
// github.com/benoitkugler/goACVE/server/vote.Vote
export interface Vote {
  id: number;
  nom: string;
  description: string;
  is_qcm: boolean;
  is_locked: boolean;
}
// github.com/benoitkugler/goACVE/server/vote.Candidat
export interface Candidat {
  id: number;
  id_vote: number;
  label: string;
}
// github.com/benoitkugler/goACVE/server/vote.VoteCandidats
export type VoteCandidats = {
  candidats: Candidat[] | null;
} & Vote;
// github.com/benoitkugler/goACVE/server/vote.VoteAdmin
export type VoteAdmin = {
  participation: number;
  voix: { [key: number]: number } | null;
} & VoteCandidats;
// github.com/benoitkugler/goACVE/server/vote.VotePersonneComplet
export type VotePersonneComplet = {
  time: Time;
  choix: Ids;
} & VoteCandidats;
